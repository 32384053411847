<template>
  <div id="sc2" style="background-color: #d1cdc1">
    <v-container>
      <div class="sp-sec2a">
        <p class="al-subtitle text-center text-sm-left">
          {{ $t("asturias.section_asturias.title") }}
        </p>
        <p class="al-normal-text text-center text-sm-left">
          {{ $t("asturias.section_asturias.text1") }}
        </p>
        <p class="al-normal-text text-center text-sm-left">
          {{ $t("asturias.section_asturias.text2") }}
        </p>
        <div class="d-flex justify-end">
          <a
            href="https://www.turismoasturias.es/"
            target="_blank"
            class="al-normal-text"
            style="text-decoration: none"
            >Turismo Asturias</a
          >
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Section2",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/shadow.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";

/* BACKGROUNDS */
.background-date {
  background-color: #d8d5cb;
  border-radius: 20px;
}

/* FONTS */
.sec2-font2 {
  letter-spacing: 5px;
}
</style>
