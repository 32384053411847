<template>
  <v-container>
    <!-- Buttons -->
    <div id="selection" class="d-none d-sm-flex flex-wrap justify-center">
      <v-btn
        :color="type === 'all' ? 'deep-purple lighten-2' : ''"
        :class="type === 'all' ? 'white--text' : 'black--text'"
        class="mx-3"
        rounded
        @click="clickEvent('all')"
      >{{ $t("gallery.photos.buttons.all") }}</v-btn>
      <v-btn
        :color="type === 'preboda' ? 'deep-purple lighten-2' : ''"
        :class="type === 'preboda' ? 'white--text' : 'black--text'"
        class="mx-3"
        rounded
        @click="clickEvent('preboda')"
      >{{ $t("gallery.photos.buttons.pre") }}</v-btn>
    </div>
    <div class="d-flex d-sm-none flex-column justify-center">
      <v-select
        :items="itemsVal"
        color="deep-purple lighten-2"
        filled
        label="Select category"
        dense
        solo
        v-model="type"
        @change="clickEvent(type)"
      ></v-select>
      <!-- <p class="mb-0">This is the type: {{ type }}</p> -->
    </div>
    <div id="grid_gallery">
      <div class="ma-general-space">
        <stack :column-min-width="300" :gutter-width="15" :gutter-height="15" monitor-images-loaded>
          <stack-item class="img-container" v-for="(item, i) in imagesLocally" :key="i">
            <img v-if="$vuetify.breakpoint.smAndDown" :src="item.url" />
            <img v-if="$vuetify.breakpoint.mdAndUp" v-img:group :src="item.url" />
          </stack-item>
        </stack>
      </div>
    </div>
  </v-container>
</template>

<script>
import { Stack, StackItem } from "vue-stack-grid";
import photos from "@/store/static/gallery.js";
export default {
  name: "Section2",
  components: { Stack, StackItem },
  data: () => ({
    type: "",
    imagesLocally: [],
    dialog: false
  }),
  methods: {
    clickEvent(event) {
      this.type = event;
      this.imagesLocally = [];
      if (event != "all") {
        photos.map(item => {
          if (item.category === event) {
            this.imagesLocally.push(item);
          }
        });
      } else this.imagesLocally = photos;
    }
  },
  computed: {
    itemsVal() {
      return [
        "all",
        "preboda",
        "espicha",
        "boda"
        // this.$t("gallery.photos.buttons.all"), this.$t("gallery.photos.buttons.pre"), this.$t("gallery.photos.buttons.espicha"), this.$t("gallery.photos.buttons.wedding")
      ];
    }
  },
  mounted() {
    this.imagesLocally = photos;
    this.type = "all";
  }
};
</script>

<style scope lang="scss">
@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: center;
  overflow: hidden;
  margin: 0;
  object-fit: fill;
  cursor: pointer;
}
.img-container img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
}
.img-container figcaption {
  margin: 3px 0;
  text-align: center;
}
</style>