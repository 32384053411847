<template>
  <div class="background-positioning flowers-background-image sec3-height">
    <v-container class="fill-height d-flex align-center">
      <div class="ma-general-space">
        <v-row class="space-row-xl-only">
          <v-col cols="12">
            <p
              class="cool-font al-subtitle pa-sec3-title"
              style="font-weight: 500"
            >
              {{ $t("home.section_welcome.welcome") }}
            </p>
            <div class="pa-sec3-textbox container-sec3 primary-shadow">
              <p class="al-normal-text">
                {{ $t("home.section_welcome.text1") }}
              </p>
              <p class="al-normal-text">
                {{ $t("home.section_welcome.text2") }}
              </p>
              <p class="al-normal-text">
                {{ $t("home.section_welcome.text3") }}
              </p>
              <p class="al-normal-text">
                {{ $t("home.section_welcome.text4") }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Section3",
};
</script>

<style scope lang="scss">
@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/responsive/background.scss";
@import "../../assets/css/responsive/space.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";
</style>