<template>
  <v-container style="height: 100%; padding: 0px !important" class>
    <div class="ma-sec1-container">
      <div
        class="background-flowers-1 dimension-flowers dimension-flowers-1"
      ></div>
      <p id="sc2.1" class="mt-10 cool-font al-subtitle">
        {{ $t("home.section_wedding.marry") }}
      </p>
      <!-- empty space -->
      <div class="py-5"></div>
      <!-- empty space -->
      <p id="sc2.2.1" class="mb-0 font-weight-bold al-heading1">
        {{ $t("home.section_wedding.rsvp") }}
      </p>
      <p id="sc2.2.2" class="mb-0 font-weight-light sec2-font2 al-heading6">
        SAVE THE DATE
      </p>
      <!-- <div id="sc2.3" class="d-inline-block py-4 px-6 my-3 background-date primary-shadow"> -->
      <p id="sc2.3" class="mb-0 py-10 font-weight-bold al-heading1">
        {{ $t("home.section_wedding.date") }}
      </p>
      <!-- </div> -->
      <p id="sc2.4.1" class="mb-0 font-weight-bold al-normal-text">
        Cangas de Onís,
      </p>
      <p id="sc2.4.2" class="mb-0 font-weight-bold al-normal-text">Asturias.</p>
      <div
        class="background-flowers-2 dimension-flowers dimension-flowers-2"
      ></div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section2",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/css/backgrounds/home.css";
@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/responsive/background.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/shadow.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";

/* BACKGROUNDS */
.background-date {
  background-color: #d8d5cb;
  border-radius: 20px;
}

/* FONTS */
.sec2-font2 {
  letter-spacing: 5px;
}
</style>
