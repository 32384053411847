<template>
  <div class="ma-general-space px-general">
    <v-container class="fill-height d-flex justify-center">
      <p class="al-subtitle text-center text-sm-left">
        {{ $t("asturias.section_testimonial.title") }}
      </p>
      <v-row
        v-for="(item, index) in users"
        :key="index"
        class="d-flex justify-center align-center"
      >
        <v-col
          cols="12"
          md="4"
          :order="item.orientation.avatar"
          class="order-avatar-sc3"
        >
          <v-img
            height="500"
            :src="item.avatar"
            class="hidden-sm-and-down"
            style="border-radius: 5% !important"
          />
          <v-avatar class="hidden-md-and-up avatar-size">
            <v-img :src="item.avatar" />
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          md="8"
          :order="item.orientation.text"
          class="order-text-sc3"
        >
          <div
            :style="{ borderColor: item.color }"
            class="
              text-container
              d-inline-block
              justify-center
              align-center
              al-pa-12
            "
          >
            <p class="al-normal-text" style="white-space: pre-line">
              {{ item.text }}
            </p>
            <p class="al-normal-text text-right">{{ item.by }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Section3",
  computed: {
    users() {
      return [
        {
          avatar: "https://d2vccncxnbf4da.cloudfront.net/asturias/adri.JPG",
          text: this.$t("asturias.section_testimonial.groom.text"),
          color: "#6D9DC5",
          by: this.$t("asturias.section_testimonial.groom.by"),
          orientation: {
            avatar: "1",
            text: "2",
          },
        },
        {
          avatar: "https://d2vccncxnbf4da.cloudfront.net/asturias/luisa.JPG",
          text: this.$t("asturias.section_testimonial.bride.text"),
          color: "#78589D",
          by: this.$t("asturias.section_testimonial.bride.by"),
          orientation: {
            avatar: "2",
            text: "1",
          },
        },
      ];
    },
  },
};
</script>

<style  scoped lang="scss">
@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/responsive/general.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/shadow.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";

.pre-formatted {
  white-space: break-spaces;
}

.text-container {
  border-radius: 7px;
  border-width: medium;
  border-style: solid;
}

.al-pa-12 {
  padding: 10px 10px 2px 10px; // this might have to be changed depending on screen size
}
</style>