<template>
  <v-container>
    <div class="px-general ma-general-space">
      <div class="greatday-mt">
        <p class="cool-font al-heading1">
          {{
            $t("great_day.espicha_section.subsection_1_what.what_is_it_title")
          }}
        </p>
        <p class="mb-0 al-heading5 espicha-px" style="white-space: pre-line">
          {{ $t("great_day.espicha_section.subsection_1_what.what_it_is") }}
        </p>
        <section class="mt-15">
          <iframe
            :width="videoWidth"
            :height="videoHeight"
            src="https://www.youtube.com/embed/dz-0bEf4SNM"
            title="Espicha Asturiana"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </section>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section2",
  computed: {
    videoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 320;
        case "sm":
          return 450;
        case "md":
          return 600;
        case "lg":
          return 700;
        case "xl":
          return 700;
        default:
          return 700;
      }
    },
    videoHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 200;
        case "sm":
          return 250;
        case "md":
          return 350;
        case "lg":
          return 400;
        case "xl":
          return 400;
        default:
          return 400;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/responsive/background.scss";
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/shadow.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>