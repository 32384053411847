<template>
  <div class="footer d-flex justify-center align-end pb-3">
    <div class="d-block">
      <!-- logo -->
      <div class="d-flex justify-center">
        <v-img
          src="https://d2vccncxnbf4da.cloudfront.net/logo/footer.png"
          width="160"
          height="160"
        ></v-img>
      </div>
      <!-- social media -->
      <div class="py-1">
        <v-btn
          color="purple"
          :ripple="false"
          class="white--text mr-2"
          x-small
          fab
          @click="goTo('https://www.instagram.com')"
        >
          <v-icon dark> mdi-instagram </v-icon>
        </v-btn>
      </div>
      <!-- funciontal contact us -->
      <div class="mb-5">
        <a href="noscasamos@adrianyluisana.com" style="text-decoration: none">{{
          $t("footer.contact")
        }}</a>
      </div>
      <p>Adrian y Luisana © {{ $t("footer.rights") }} 2022</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    goTo(link) {
      console.log(link);
      window.open(link);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/css/responsive/background.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/shadow.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";

.footer {
  height: 350px;
  // D1CDC1
  // F9FAFC
  background-color: #f9fafc;
}
</style>