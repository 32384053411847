<template>
  <div class="ma-general-space px-general">
    <v-container class="fill-height d-flex justify-center">
      <p class="al-subtitle">{{ $t("asturias.section_destination.title") }}</p>
      <v-row id="30km" class="mb-10">
        <v-col cols="12" md="12" class="d-inline-block">
          <p class="al-heading3 text-center">
            {{ $t("asturias.section_destination.30km_cangas.title") }}
          </p>
          <p class="al-normal-text text-center">
            {{ $t("asturias.section_destination.30km_cangas.text1") }}
          </p>
          <p class="al-normal-text text-center">
            {{ $t("asturias.section_destination.30km_cangas.text2") }}
          </p>
          <p class="al-normal-text text-center">
            {{ $t("asturias.section_destination.30km_cangas.text3") }}
            <a
              href="www.turismoasturias.com"
              target="_blank"
              style="text-decoration: none"
              >turismo asturias</a
            >.
          </p>
        </v-col>
        <v-col cols="12" md="12" class>
          <hooper :settings="hooperSettings" class="card-height">
            <slide
              v-for="(item, index) in localDestination"
              :key="index"
              class="d-flex justify-center"
            >
              <Card
                :name="item.name"
                :image="item.image"
                :link="item.link"
                parentc="asturias"
                cardWidth="350"
              />
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
        </v-col>
      </v-row>
      <v-row id="lookouts" class="my-10">
        <v-col cols="12" md="12" class="d-inline-block">
          <p class="al-heading3 text-center">
            {{ $t("asturias.section_destination.lookouts.title") }}
          </p>
          <p class="al-normal-text text-center">
            {{ $t("asturias.section_destination.lookouts.text1") }}
          </p>
          <p class="al-normal-text text-center">
            {{ $t("asturias.section_destination.lookouts.text2") }}
          </p>
        </v-col>
        <v-col cols="12" md="12" class>
          <hooper :settings="hooperSettings" class="card-height">
            <slide
              v-for="(item, index) in localLookouts"
              :key="index"
              class="d-flex justify-center"
            >
              <Card
                :name="item.name"
                :image="item.image"
                :link="item.link"
                parentc="asturias"
                cardWidth="350"
              />
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
        </v-col>
      </v-row>
      <v-row id="cities" class="my-10">
        <v-col cols="12" md="12" class="d-inline-block">
          <p class="al-heading3 text-center">
            {{ $t("asturias.section_destination.cities.title") }}
          </p>
          <p class="al-normal-text text-center">
            {{ $t("asturias.section_destination.cities.text1") }}
          </p>
          <p class="al-normal-text text-center">
            {{ $t("asturias.section_destination.cities.text2") }}
          </p>
          <p class="al-normal-text text-center">
            {{ $t("asturias.section_destination.cities.text3") }}
          </p>
          <p class="al-normal-text text-center">
            {{ $t("asturias.section_destination.cities.text4") }}
          </p>
        </v-col>
        <v-col cols="12" md="12" class>
          <hooper :settings="hooperSettings" class="card-height">
            <slide
              v-for="(item, index) in localCities"
              :key="index"
              class="d-flex justify-center"
            >
              <Card
                :name="item.name"
                :image="item.image"
                :link="item.link"
                parentc="asturias"
                cardWidth="350"
              />
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import Card from "../common/Card.vue";
import places from "@/store/static/places.js";
import "hooper/dist/hooper.css";
export default {
  name: "Section4",
  data: () => ({
    localDestination: [],
    localLookouts: [],
    localCities: [],
    hooperSettings: {
      trimWhiteSpace: true,
      itemsToShow: 1,
      breakpoints: {
        340: {
          itemsToShow: 1,
        },
        540: {
          itemsToShow: 1,
        },
        800: {
          itemsToShow: 2,
        },
        1280: {
          itemsToShow: 3,
        },
      },
    },
  }),
  components: {
    Hooper,
    Slide,
    HooperPagination,
    Card,
  },
  mounted() {
    this.localDestination = places.destination;
    this.localLookouts = places.lookouts;
    this.localCities = places.cities;
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/responsive/space.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/shadow.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";

.card-height {
  height: 400px;
}
</style>