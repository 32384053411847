<template>
  <div>
    <p class="cool-font al-heading1" style="font-weight: 500">
      {{ $t("home.section_form.rsvp.title") }}
    </p>
    <p class="al-normal-text text-left">
      {{ $t("home.section_form.transportation.covadonga") }}
    </p>
    <v-radio-group :rules="rules.cotrans" v-model="event.cotrans" column>
      <v-radio
        :label="$t('home.section_form.transportation.yes')"
        value="Sí"
        color="primary"
      ></v-radio>
      <v-radio
        :label="$t('home.section_form.transportation.no')"
        value="No"
        color="primary"
      ></v-radio>
    </v-radio-group>
    <p class="al-normal-text text-left">
      {{ $t("home.section_form.transportation.espicha") }}
    </p>
    <v-radio-group :rules="rules.estrans" v-model="event.estrans" column>
      <v-radio
        :label="$t('home.section_form.transportation.yes')"
        value="Sí"
        color="primary"
      ></v-radio>
      <v-radio
        :label="$t('home.section_form.transportation.no')"
        value="No"
        color="primary"
      ></v-radio>
    </v-radio-group>
    <v-row>
      <v-col cols="6">
        <v-btn @click="editRSVP('rsvp_details')">{{
          $t("home.section_form.buttons.back")
        }}</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn @click="send(event)">{{
          $t("home.section_form.buttons.send")
        }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TransportationForm",
  data: () => ({
    event: {
      cotrans: "",
      estrans: "",
    },
  }),
  methods: {
    ...mapActions(["postRSVP", "editRSVP"]),
    send(event) {
      if (this.parentRefs.form.validate()) {
        this.postRSVP(event);
        this.$emit("invokeLambda");
        this.parentRefs.form.reset();
      }
    },
  },
  computed: {
    // rules
    rules() {
      let estrans, cotrans;
      if ((this.event.estrans && this.event.cotranst) != null) {
        estrans = [
          this.event.estrans.length > 0 ||
            this.$t("home.section_form.rules.selection"),
        ];
        cotrans = [
          this.event.cotrans.length > 0 ||
            this.$t("home.section_form.rules.selection"),
        ];
      }
      return { estrans, cotrans };
    },
  },
  props: {
    parentRefs: Object,
  },
};
</script>

<style>
</style>