import {
    loadLocale,
    changeLocale,
} from "./locale"

import {
    changeMapPin
} from "./maps"

import {
    updateRSVP,
    editRSVP,
    postRSVP
} from "./invitations"

export default {
    loadLocale,
    changeLocale,
    changeMapPin,
    updateRSVP,
    editRSVP,
    postRSVP
}