<template>
  <div id="gallery">
    <v-app>
      <Header />
      <div
        class="background-gallery position-gallery"
        style="padding-bottom: 25rem"
      >
        <div id="gallery_sc1">
          <Section1 />
        </div>
        <div id="gallery_sc2">
          <Section2 />
        </div>
      </div>
      <div id="bottom">
        <Footer />
      </div>
    </v-app>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import Section1 from "@/components/gallery/Section1.vue";
import Section2 from "@/components/gallery/Section2.vue";

export default {
  name: "Gallery",
  components: {
    Header,
    Footer,
    Section1,
    Section2,
  },
};
</script>

<style lang="scss">
@import "../assets/css/responsive/background.scss";
@import "../assets/css/backgrounds/gallery.css";
</style>