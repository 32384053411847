<template>
  <v-container>
    <div class="px-general">
      <div
        class="d-flex align-end justify-center"
        style="white-space: pre-line; line-height: 0.8; margin-top: 4rem"
      >
        <p class="cool-font al-subtitle">
          {{ $t("great_day.celebration_section.title") }}
        </p>
      </div>
      <div class="greatday-pt-text greatday-mt-sec1">
        <p class="al-heading5 text-center">
          {{ $t("great_day.celebration_section.subsection1") }}
        </p>
      </div>
      <div class="greatday-pt-text">
        <p class="al-heading5 text-center">
          {{ $t("great_day.celebration_section.subsection2") }}
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section1",
};
</script>

<style lang="scss">
@import "../../../assets/css/responsive/background.scss";
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/shadow.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>