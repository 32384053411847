<template>
  <v-container>
    <div class="px-general">
      <div class="greatday-pt-text">
        <p class="cool-font al-heading3 text-center">
          {{ $t("great_day.celebration_section.reserve_hotel") }}
        </p>
        <p class="mb-0 al-heading5">
          {{ $t("great_day.celebration_section.reserve_hotel_details") }}
        </p>
      </div>
      <div class="greatday-pt-text" style="padding-top: 3.5rem !important">
        <p class="cool-font al-heading3 text-center">
          {{ $t("great_day.celebration_section.location") }}
        </p>
        <p class="mb-0 al-heading5" style="white-space: pre-line">
          {{ $t("great_day.celebration_section.location_details") }}
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section1",
};
</script>

<style lang="scss">
@import "../../../assets/css/responsive/background.scss";
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/shadow.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>