// get current date
// var today = new Date();
// var dd = String(today.getDate()).padStart(2, '0');
// var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
// var yyyy = today.getFullYear();

export const updateRSVP = (state, event) => {
    if (event.allergyType === "") event.allergyType = "N/A"
    state.formState = "transportation_details"
    state.rsvpEvent = event
}

export const editRSVP = (state, status) => {
    state.formState = status
}

export const postRSVP = (state, event) => {
    const finalResult = Object.assign(state.rsvpEvent, event);
    // finalResult.confirmationDate = mm + '/' + dd + '/' + yyyy;
    finalResult.date = new Date()
    state.formState = "rsvp_details"
    state.rsvpEvent = finalResult // this will get sent to DynamoDB
}