<template>
  <v-container>
    <div class="px-general ma-general-space">
      <div class="greatday-mt">
        <p class="cool-font al-heading1">
          {{
            $t("great_day.espicha_section.subsection_1_what.what_to_find_title")
          }}
        </p>
        <p class="mb-0 al-heading5 espicha-px" style="white-space: pre-line">
          {{ $t("great_day.espicha_section.subsection_1_what.what_to_find") }}
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section3",
};
</script>

<style lang="scss">
@import "../../../assets/css/responsive/background.scss";
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/shadow.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>