<template>
  <!-- max-width can dynamically be set -->
  <v-card class="ma-3" :max-width="cardWidth">
    <v-img height="250" :src="image"></v-img>

    <v-card-title>{{ name }}</v-card-title>
    <v-card-subtitle
      class="d-flex align-center"
      v-if="parentc === 'accommodation'"
    >
      <div>
        <v-avatar class="elevation-3" size="30">
          <v-icon
            :class="category === 'Hotels' ? 'purple--text' : 'blue--text'"
            size="20"
            >{{ category === "Hotels" ? "mdi-bed" : "mdi-home" }}</v-icon
          >
        </v-avatar>
        {{ category === "Hotels" ? "Hotel" : "Casa Rural" }}
      </div>
      <!-- <div class="ml-10">
        <v-avatar class="elevation-3" size="30">
          <v-icon class="yellow--text text--darken-3" size="20"
            >mdi-google-maps</v-icon
          >
        </v-avatar>
        Distancia: {{ distance }}km
      </div> -->
    </v-card-subtitle>

    <v-card-actions class="d-flex justify-space-between">
      <v-btn
        v-if="parentc === 'asturias'"
        @click="goTo(link)"
        color="deep-purple lighten-2"
        text
        >{{ $t("asturias.section_destination.info") }}</v-btn
      >
      <v-btn
        v-if="parentc === 'accommodation'"
        @click="changeMapPin(dCoordinates)"
        color="deep-purple lighten-2"
        text
        >{{ $t("accommodation.button") }}</v-btn
      >
      <v-btn
        v-if="parentc === 'accommodation'"
        @click="goTo(website)"
        style="background-color: #d1cdc1 !important; color: #ffff !important"
        color="deep-purple lighten-2"
        text
        >{{ $t("accommodation.places.buttons.reserve") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Card",
  data: () => ({
    distance: "",
  }),
  methods: {
    ...mapActions(["changeMapPin"]),
    goTo(link) {
      window.open(link);
    },
  },
  computed: {
    ...mapState(["coordinates"]),
  },
  props: {
    name: String,
    image: String,
    link: String,
    website: String,
    category: String, // only for accommodation
    parentc: String,
    dCoordinates: Object, // destination coordinates
    cardWidth: String,
  },
};
</script>

<style>
</style>