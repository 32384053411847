<template>
  <div>
    <!-- <v-container> -->
    <div class="ma-general-space px-general">
      <div class="map">
        <div class="overcard position-of-accommodations">
          <div class="selector">
            <v-select
              :items="itemsVal"
              color="deep-purple lighten-2"
              filled
              :label="$t('accommodation.places.select')"
              dense
              solo
              v-model="type"
              @change="clickEvent(type)"
            ></v-select>
          </div>
          <div>
            <v-virtual-scroll
              :bench="benched"
              :items="localAccommodation"
              height="440"
              item-height="420"
            >
              <template v-slot:default="{ item }">
                <div :key="item.name" class="mt-5 mb-5">
                  <div class="d-flex justify-center px-5 py-1">
                    <Card
                      :name="item.name"
                      :image="item.image"
                      :category="item.category"
                      :website="item.website"
                      parentc="accommodation"
                      cardWidth="300"
                      :dCoordinates="item.coordinates"
                    />
                  </div>
                </div>
              </template>
            </v-virtual-scroll>
          </div>
        </div>
        <div id="google-maps">
          <google-map />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../common/Card.vue";
import GoogleMap from "../common/GoogleMaps.vue";
import accommodation from "@/store/static/accommodations.js";

export default {
  name: "Section2Desktop",
  components: {
    GoogleMap,
    Card,
  },
  data: () => ({
    // location: variable sent the card component
    benched: 0,
    localAccommodation: [],
    type: "",
    distance: "",
  }),
  methods: {
    clickEvent(event) {
      this.type = event;
      if (event === "Hoteles") event = "Hotels";
      else if (event === "Casas Rurales") event = "Rural Houses";
      this.localAccommodation = [];
      if (event != "All" && event != "Todos") {
        accommodation.map((item) => {
          if (item.category === event) {
            this.localAccommodation.push(item);
          }
        });
      } else this.localAccommodation = accommodation;
    },
  },
  computed: {
    itemsVal() {
      return [
        this.$t("accommodation.places.buttons.all"),
        this.$t("accommodation.places.buttons.hotels"),
        this.$t("accommodation.places.buttons.houses"),
      ];
    },
  },
  mounted() {
    this.localAccommodation = accommodation;
  },
};
</script>

<style scope lang="scss">
@import "../../assets/css/responsive/space.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";

.map {
  position: relative;
}

.overcard {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  background: #fff;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20px;
  white-space: normal;
  width: 400px;
  z-index: 1;
}

.selector {
  background: rgba(0, 0, 0, 0.04);
  padding: 16px;
}
</style>