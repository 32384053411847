import i18n from '@/plugins/i18n'

export const loadLocale = ({ dispatch }) => {
    let locale = 'es'
    const storageLocale = JSON.parse(localStorage.getItem('locale'))
    if (storageLocale) locale = storageLocale
    else {
        let browserLocales = window.navigator.languages === undefined ? [window.navigator.language] : window.navigator.languages
        browserLocales.map(e => e.trim().split('-')[0])
        locale = browserLocales.includes('es') ? 'es' : 'en'
    }
    dispatch('changeLocale', locale)
}

export const changeLocale = (ctx, locale) => {
    i18n.locale = locale
    localStorage.setItem('locale', JSON.stringify(locale))
}