import { render, staticRenderFns } from "./Section2Desktop.vue?vue&type=template&id=6cea3ac0&"
import script from "./Section2Desktop.vue?vue&type=script&lang=js&"
export * from "./Section2Desktop.vue?vue&type=script&lang=js&"
import style0 from "./Section2Desktop.vue?vue&type=style&index=0&scope=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VSelect,VVirtualScroll})
