<template>
  <v-container>
    <div class="px-general ma-general-space">
      <div class="greatday-mt">
        <p class="espicha-sub-mt cool-font al-heading3">
          {{ $t("great_day.how_to_get_title") }}
        </p>
        <p class="mb-0 al-heading5 espicha-px">
          {{ $t("great_day.ceremony_section.how_to_get") }}
        </p>
        <p class="espicha-sub-mt cool-font al-heading3">
          {{ $t("great_day.dress_code_title") }}
        </p>
        <p class="mb-0 al-heading5 espicha-px">
          {{ $t("great_day.ceremony_section.dress_code") }}
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section3",
};
</script>

<style lang="scss">
@import "../../../assets/css/responsive/background.scss";
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/shadow.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>