var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"header",style:({ backgroundColor: _vm.dynamic }),attrs:{"app":"","elevate-on-scroll":""}},[_c('v-toolbar-title',{staticClass:"header-title cursor-pointer",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.navigateTo({ name: 'home' })}}},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"AdrianYLuisanaLogo","contain":"","src":"https://d2vccncxnbf4da.cloudfront.net/logo/logo_03_ai_logo encabezado.png","transition":"scale-transition","width":"40"}})],1),_c('v-spacer'),_c('v-toolbar-items',[_vm._l((_vm.routes),function(route){return _c('v-btn',{key:route.id,staticClass:"hidden-sm-and-down text-capitalize",attrs:{"ripple":false,"text":"","to":route}},[_vm._v(_vm._s(_vm.$t(("routes." + route))))])}),_c('v-menu',{attrs:{"offset-y":_vm.offset,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down text-capitalize black--text",attrs:{"text":"","ripple":false,"dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("routes.greatday"))+" ")])]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,staticClass:"cursor-pointer v-items"},[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"ripple":false,"text":"","to":item}},[_vm._v(_vm._s(_vm.$t(("routes." + item))))])],1)],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"ripple":false,"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.locale)+" "),_c('v-img',{staticClass:"ml-1",attrs:{"src":_vm.locale_flag,"contain":"","width":"20"}})],1)]}}])},[_c('v-list',_vm._l((_vm.locales),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.changeLocale(item.value)}}},[_c('v-list-item-title',[_c('v-btn',{staticClass:"text-capitalize",attrs:{"ripple":false,"text":""}},[_vm._v(" "+_vm._s(item.text)+" "),_c('v-img',{staticClass:"ml-1",attrs:{"src":item.flag,"contain":"","width":"20"}})],1)],1)],1)}),1)],1),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-md-and-up",attrs:{"icon":""}},on),[_c('v-icon',{},[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.routesMobile),function(route){return _c('v-list-item',{key:route.id,staticClass:"cursor-pointer v-items"},[_c('v-list-item-title',[_c('v-btn',{attrs:{"ripple":false,"text":"","to":route}},[_vm._v(_vm._s(_vm.$t(("routes." + route))))])],1)],1)}),1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }