<template>
  <div id="espicha">
    <v-app>
      <Header />
      <div
        class="
          background-flowers-1
          dimension-flowers dimension-flowers-1
          flower-size-greatday
        "
        id="greatday_sc_image"
      />
      <div id="great_day_sc1">
        <Section1 />
      </div>
      <!-- <div
        class="background-positioning flowers-background-image"
        id="espicha_sc1"
      >
        <Section1 />
      </div> -->
      <div id="great_day_sc2">
        <Section2 />
      </div>
      <div id="great_day_sc3" style="padding-bottom: 25rem">
        <Section3 />
      </div>
      <div id="bottom">
        <Footer />
      </div>
    </v-app>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import Section1 from "@/components/great_day/ceremony/Section1.vue";
import Section2 from "@/components/great_day/ceremony/Section2.vue";
import Section3 from "@/components/great_day/ceremony/Section3.vue";

export default {
  name: "GreatDay",
  components: {
    Header,
    Footer,
    Section1,
    Section2,
    Section3,
  },
};
</script>

<style lang="scss">
@import "../assets/css/responsive/background.scss";
@import "../assets/css/backgrounds/gallery.css";
</style>