<template>
  <v-container class style="height: 100%">
    <div class="d-flex align-center" style="height: 100%">
      <v-img
        contain
        class="invitation"
        src="https://d2vccncxnbf4da.cloudfront.net/invitations/savethedate2.jpeg"
      />
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Invitation",
};
</script>

<style scope lang="scss">
@import "../assets/css/common/font.css";
@import "../assets/css/colors.css";
@import "../assets/css/boxes.css";
</style>