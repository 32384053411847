<template>
  <div>
    <v-container>
      <div class="px-general ma-general-space">
        <div class="greatday-mt">
          <p class="mb-0 al-heading5 espicha-px" style="white-space: pre-line">
            {{ $t("great_day.ceremony_section.subsection2") }}
          </p>
        </div>
      </div>
    </v-container>
    <div class="pa-sec2a-brownbackground" style="background-color: #d1cdc1">
      <p class="mb-0 pb-5 font-weight-bold al-heading1">
        {{ $t("home.section_wedding.date") }}
      </p>
      <p class="mb-0 font-weight-bold al-normal-text">
        {{ $t("great_day.ceremony_section.ceremony_details_date2") }}
      </p>
      <p class="mb-0 al-normal-text">
        {{ $t("great_day.ceremony_section.ceremony_details_date3") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section2",
};
</script>

<style lang="scss">
@import "../../../assets/css/responsive/background.scss";
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/shadow.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>