<template>
  <v-form :ref="form" class="forms background">
    <RSVPForm :parentRefs="$refs" v-if="formState == 'rsvp_details'" />
    <TransportationForm
      :parentRefs="$refs"
      v-on:invokeLambda="invokeLambda()"
      v-if="formState == 'transportation_details'"
    />
    <v-snackbar :color="color" :timeout="snacktimeout" v-model="alert">
      <div class="d-flex flex-row align-center justify-space-between">
        <p class="mb-0">{{ text }}</p>
        <v-btn color="white" text @click="alert = false">
          <v-icon small>mdi-window-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
  </v-form>
</template>

<script>
import { mapState } from "vuex";
import { getAwsInfo } from "../../assets/myAwsInfo.js";
import TransportationForm from "@/components/home/Section5/TransportationForm.vue";
import RSVPForm from "@/components/home/Section5/RSVPForm.vue";

// This could be externalize into a utils file
// Setting up AWS: Lambda Function
var AWS = require("aws-sdk");
var awsConfig = getAwsInfo();

AWS.config.update({
  region: awsConfig.region,
});

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: awsConfig.identityPoolId,
});

var lambda = new AWS.Lambda({
  region: awsConfig.region,
  apiVersion: "2015-03-31",
});

export default {
  name: "Forms",
  data: () => ({
    form: "form",
    // snack bar
    alert: false,
    color: "",
    text: "",
    snacktimeout: 8000,
  }),
  methods: {
    async invokeLambda() {
      var buf = Buffer.from(JSON.stringify(this.rsvpEvent)); // getting event from store
      var params = {
        FunctionName: process.env.VUE_APP_FUNCTION,
        InvocationType: "RequestResponse",
        Payload: buf,
      };
      await lambda
        .invoke(params)
        .promise()
        .then((data) => {
          var res = JSON.parse(data.Payload);
          console.log("returned results: ", JSON.stringify(res, null, 2));
          this.showsnackbar(
            this.$t("form_snackbar_message.success"),
            "success",
            true
          );
        })
        .catch((err) => {
          console.log(err);
          this.showsnackbar(
            this.$t("form_snackbar_message.error"),
            "error",
            true
          );
        });
    },
    showsnackbar(text, color, alert) {
      this.text = text;
      this.color = color;
      this.alert = alert;
    },
  },
  computed: {
    ...mapState(["formState", "rsvpEvent"]),
  },
  components: {
    TransportationForm,
    RSVPForm,
  },
};
</script>

<style scope lang="scss">
@import "../../assets/css/common/font.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";
@import "../../assets/css/forms.css";
</style>