<template>
  <div id="accommodation">
    <v-app>
      <Header />
      <div id="accommodation_sc1">
        <Section1 />
      </div>
      <div
        id="accommodation_sc2d"
        style="padding-bottom: 25rem"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <Section2Desktop />
      </div>
      <div
        id="accommodation_sc2m"
        style="padding-bottom: 25rem"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <Section2Mobile />
      </div>
      <div id="bottom">
        <Footer />
      </div>
    </v-app>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import Section1 from "@/components/accommodation/Section1.vue";
import Section2Desktop from "@/components/accommodation/Section2Desktop.vue";
import Section2Mobile from "@/components/accommodation/Section2Mobile.vue";
// import Section3 from "@/components/accommodation/Section3.vue";

export default {
  name: "Accommodation",
  components: {
    Header,
    Footer,
    Section1,
    Section2Desktop,
    Section2Mobile,
    // Section3,
  },
};
</script>

<style scope lang="scss">
@import "../assets/css/backgrounds/accommodation.css";
</style>