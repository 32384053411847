<template>
  <div id="asturias">
    <v-app>
      <Header />
      <div id="asturias_sc1">
        <Section1 />
      </div>
      <div id="asturias_sc2">
        <Section2 />
      </div>
      <div id="asturias_sc3">
        <Section3 />
      </div>
      <div id="asturias_sc4" style="padding-bottom: 25rem">
        <Section4 />
      </div>
      <div id="bottom">
        <Footer />
      </div>
    </v-app>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import Section1 from "@/components/asturias/Section1.vue";
import Section2 from "@/components/asturias/Section2.vue";
import Section3 from "@/components/asturias/Section3.vue";
import Section4 from "@/components/asturias/Section4.vue";

export default {
  name: "Asturias",
  components: {
    Header,
    Footer,
    Section1,
    Section2,
    Section3,
    Section4,
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/backgrounds/asturias.css";
</style>