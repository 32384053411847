export const updateRSVP = (context, event) => {
    context.commit("updateRSVP", event)
}

export const editRSVP = (context, status) => {
    context.commit("editRSVP", status)
}

export const postRSVP = (context, event) => {
    context.commit("postRSVP", event)
}