<template>
  <div id="espicha">
    <v-app>
      <Header />
      <div
        class="
          background-flowers-1
          dimension-flowers dimension-flowers-1
          flower-size-greatday
        "
        id="celebration_sc_image"
      />
      <div id="espicha_sc1">
        <Section1 />
      </div>
      <div id="great_day_sc2" style="padding-bottom: 25rem">
        <Section2 />
      </div>
      <div id="bottom">
        <Footer />
      </div>
    </v-app>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import Section1 from "@/components/great_day/celebration/Section1.vue";
import Section2 from "@/components/great_day/celebration/Section2.vue";

export default {
  name: "GreatDay",
  components: {
    Header,
    Footer,
    Section1,
    Section2,
  },
};
</script>

<style lang="scss">
@import "../assets/css/responsive/background.scss";
@import "../assets/css/backgrounds/gallery.css";
</style>