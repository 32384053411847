<template>
  <v-container class>
    <div class="px-general">
      <!-- Introductory -->
      <div class="d-flex align-end justify-center" style="margin-top: 4rem">
        <div class>
          <p class="cool-font al-subtitle">{{ $t("gallery.main.title") }}</p>
          <p
            class="al-normal-text text-left pt-15"
            style="white-space: pre-line"
          >{{ $t("gallery.main.text") }}</p>
        </div>
      </div>
      <!-- Google Photos -->
      <div class="px-13 mt-10">
        <p class="cool-font al-heading2 text-center text-md-left">{{ $t("gallery.works.title") }}</p>
        <!-- <v-row no-gutters class="test-4 pa-15"> -->
        <v-row no-gutters class>
          <v-col cols="12" md="8" class="d-flex align-center">
            <v-row>
              <v-col cols="12" md="4">
                <div class="d-inline-block justify-center">
                  <v-img src="https://d2vccncxnbf4da.cloudfront.net/gallery/qr.jpg" width="120" />
                </div>
                <p class="text-center">
                  {{ $t("gallery.works.step1") }}
                  <a
                    href="https://photos.app.goo.gl/nJykNVbisjjsYn7i9"
                    target="_blank"
                  >{{ $t("gallery.works.link") }}</a>
                  {{ $t("gallery.works.step2") }}
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-inline-block justify-center">
                  <v-img
                    src="https://d2vccncxnbf4da.cloudfront.net/gallery/google_photos_logo.png"
                    width="120"
                  />
                </div>
                <p class="text-center">{{ $t("gallery.works.step3") }}</p>
                <div class="d-inline-block justify-center">
                  <a href="https://apps.apple.com/us/app/google-photos/id962194608" target="_blank">
                    <v-img
                      src="https://d2vccncxnbf4da.cloudfront.net/gallery/appstore.png"
                      width="90"
                    />
                  </a>
                </div>
                <span class="gallery-ml-2 gallery-mr-2" />
                <div class="d-inline-block justify-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.photos&hl=en&gl=US"
                    target="_blank"
                  >
                    <v-img
                      src="https://d2vccncxnbf4da.cloudfront.net/gallery/googleplay.png"
                      width="90"
                    />
                  </a>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-inline-block justify-center">
                  <v-img
                    src="https://d2vccncxnbf4da.cloudfront.net/gallery/photos.png"
                    width="120"
                  />
                </div>
                <p class="text-center">{{ $t("gallery.works.step4") }}</p>
              </v-col>
            </v-row>
            <!-- <div>
            </div>-->
          </v-col>
          <v-col cols="12" md="4" class="d-flex justify-center">
            <div class>
              <v-img
                src="https://d2vccncxnbf4da.cloudfront.net/gallery/phone_structure.jpg"
                width="200"
              />
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- Easy enough -->
      <div class="px-13 mt-10">
        <p class="cool-font al-heading3">{{ $t("gallery.ease.title") }}</p>
        <p class="al-normal-text">{{ $t("gallery.ease.text1") }}</p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section1"
};
</script>

<style scoped lang="scss">
@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";
</style>