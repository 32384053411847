<template>
  <v-container style="height: 100%">
    <div class="ma-general-space">
      <div id="title" class="mb-10 d-flex justify-center align-center">
        <div class="section4-flower-sizes section4a-background-flowers" />
        <p class="cool-font al-subtitle" style="font-weight: 500">
          {{ $t("home.section_detail.details") }}
        </p>
        <div class="section4-flower-sizes section4b-background-flowers" />
      </div>
      <v-row class="space-row-xl-only">
        <!-- Dynamic rendering -->
        <v-col
          cols="12"
          sm="12"
          md="4"
          v-for="detail in list"
          :key="detail.title"
        >
          <!-- {{ $t("home.section_detail.details[title]") }} -->
          <!-- {{ $t("message[city]") }} -->
          <Detail
            :title="detail.title"
            :image="detail.image"
            :date="detail.date"
            :time="detail.time"
            :address="detail.address"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Detail from "./Details.vue";
export default {
  name: "Section4",
  data: () => ({
    details: [],
  }),
  computed: {
    list() {
      return [
        {
          title: "Espicha Asturiana",
          image: "https://d2vccncxnbf4da.cloudfront.net/details/espicha.jpeg",
          date: this.$t("home.section_detail.date"),
          time: "17.30 - 20.30",
          address: `Aldea San Juan, 44, 33311
  Villaviciosa, Asturias`,
        },
        {
          title: this.$t("home.section_detail.ceremony"),
          image:
            "https://d2vccncxnbf4da.cloudfront.net/details/covadonga2.jpeg",
          date: this.$t("home.section_detail.date2"),
          time: "19.15 - 20.00",
          address: `Lugar Covadonga, s/n, 33589
  Covadonga, Asturias`,
        },
        {
          title: this.$t("home.section_detail.dinner"),
          image: "https://d2vccncxnbf4da.cloudfront.net/details/cena.jpeg",
          date: this.$t("home.section_detail.date2"),
          time: "20.30 - 5.00",
          address: `Parador de Cangas de Onís
  Villanueva de Cangas, s/n, 33350`,
        },
      ];
    },
  },
  components: {
    Detail,
  },
};
</script>

<style scope lang="scss">
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/responsive/space.scss";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
.container-welcome {
  background-color: #d8d5cb;
  border-radius: 20px;
}
</style>