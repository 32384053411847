export const destination = [
    {
        name: "Lagos de Covadonga",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/lagos.jpg",
        link: "https://lagosdecovadonga.org/",
    },
    {
        name: "Picos de Europa",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/picos.jpg",
        link: "https://www.turismoasturias.es/descubre/naturaleza/reservas-de-la-biosfera/parque-nacional-de-los-picos-de-europa",
    },
    {
        name: "Poncebos",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/poncebos.jpg",
        link: "https://www.rurismo.com/pueblo/asturias/poncebos--17455/",
    },
    {
        name: "Bufones de Pria",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/bufones.jpg",
        link: "https://www.barcelo.com/guia-turismo/es/espana/asturias/que-ver/bufones-de-pria/",
    },
    {
        name: "Llanes",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/llanes.jpg",
        link: "https://www.llanes.es/",
    },
    {
        name: "Ribadesela",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/ribadesela.jpg",
        link: "https://www.ribadesella.es/es",
    },
    {
        name: "Llastres",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/llastres.jpg",
        link: "https://www.lospueblosmasbonitosdeespana.org/principado-de-asturias/www-mapallastres-es",
    },
    {
        name: "Cangas de Onís",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/cangas.jpg",
        link: "https://turismocangasdeonis.com/",
    },
]
export const lookouts = [
    {
        name: "Mirador del Sablón",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/sablon.jpg",
        link: "https://www.turismoasturias.es/blog/-/blogs/los-bancos-con-las-mejores-vistas-en-asturias",
    },
    {
        name: "Mirador del Fitu",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/fitu.jpg",
        link: "https://asturias.com/mirador-del-fitu/",
    },
    {
        name: "Mirador de la Regalina",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/regalina.jpg",
        link: "https://espanafascinante.com/lugar-para-visitar/que-ver-en-asturias/ermita-regalina-cadavedo-asturias/",
    },
    {
        name: "Mirador del Cabo Peñas",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/cabo.jpg",
        link: "https://unviajecreativo.com/cabo-penas-faro-ruta/",
    },
]
export const cities = [
    {
        name: "Avilés - Centro Niemeyer",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/aviles.jpg",
        link: "https://www.turismoasturias.es/descubre/ciudades/aviles",
    },
    {
        name: "Oviedo - Plaza de Catedral",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/oviedo.jpg",
        link: "https://www.turismoasturias.es/descubre/ciudades/oviedo",
    },
    {
        name: "Gijón - Vista aerea",
        image: "https://d2vccncxnbf4da.cloudfront.net/asturias/gijon.jpg",
        link: "https://www.turismoasturias.es/descubre/ciudades/gijon",
    },
]

export default { destination, lookouts, cities }