<template>
  <div id="home">
    <v-app>
      <Header />
      <div id="home_sc1">
        <Section1 />
      </div>
      <div id="home_sc2">
        <Section2 />
      </div>
      <div id="home_sc2a">
        <Section2a />
      </div>
      <div id="home_sc3">
        <Section3 />
      </div>
      <div id="home_sc4">
        <Section4 />
      </div>
      <div id="home_sc5" style="padding-bottom: 25rem">
        <Section5 />
      </div>
      <div id="bottom">
        <Footer />
      </div>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import Section1 from "@/components/home/Section1.vue";
import Section2 from "@/components/home/Section2.vue";
import Section2a from "@/components/home/Section2a.vue";
import Section3 from "@/components/home/Section3.vue";
import Section4 from "@/components/home/Section4/Section4.vue";
import Section5 from "@/components/home/Section5/Section5.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Section1,
    Section2,
    Section2a,
    Section3,
    Section4,
    Section5,
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/backgrounds/home.css";
</style>