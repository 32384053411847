import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Invitation from '../views/Invitation.vue'
import Espicha from '../views/Espicha.vue'
import Ceremony from '../views/Ceremony.vue'
import Celebration from '../views/Celebration.vue'
import Asturias from '../views/Asturias.vue'
import Gallery from '../views/Gallery.vue'
import Travel from '../views/Travel.vue'
import Accommodation from '../views/Accommodation.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/asturias',
    name: 'asturias',
    component: Asturias
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },
  {
    path: '/accommodation',
    name: 'accommodation',
    component: Accommodation
  },
  {
    path: '/invitations',
    name: 'invitations',
    component: Invitation
  },
  {
    path: '/espicha',
    name: 'espicha',
    component: Espicha
  },
  {
    path: '/ceremony',
    name: 'ceremony',
    component: Ceremony
  },
  {
    path: '/celebration',
    name: 'celebration',
    component: Celebration
  },
  {
    path: '/travel',
    name: 'travel',
    component: Travel
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history', // would this do the trick?
  base: process.env.BASE_URL,
  routes
})

export default router
