<template>
  <v-container>
    <div class="px-general">
      <v-row class="hidden-sm-and-down">
        <v-col
          cols="3"
          v-for="(item, index) in travel"
          :key="index"
          class="d-flex justify-center"
        >
          <v-btn
            :color="item.color"
            :ripple="false"
            class="ma-2 white--text"
            x-large
            fab
            @click="displayPannelInfo(item)"
          >
            <v-icon dark> mdi-{{ item.icon }} </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="hidden-md-and-up">
        <v-select
          :items="itemsVal"
          color="deep-purple lighten-2"
          filled
          :label="$t(`travel.select`)"
          dense
          solo
          v-model="type"
          @change="clickEvent(type)"
        ></v-select>
      </div>
      <div
        id="dynamic_element_rendering"
        class="d-flex justify-center"
        style="padding-top: 3rem !important"
      >
        <v-card width="1000" eleveation="11" class="travel-pannel-outer my-10">
          <div class="travel-pannel-inner">
            <p class="cool-font al-heading3">
              {{ title }}
            </p>
            <p class="al-heading5 text-center">
              {{ description }}
            </p>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section1",
  data: () => ({
    state: "foldedState",
    title: "",
    description: "",
    type: "",
  }),
  methods: {
    displayPannelInfo(transportation_way) {
      this.state = "unfoldedState";
      this.title = transportation_way.title;
      this.description = transportation_way.description;
    },
    closePanel() {
      this.state = "foldedState";
    },
    clickEvent(event) {
      this.state = "unfoldedState";
      this.type = event;
      console.log(this.type);
      this.travel.map((item) => {
        if (item.id == this.type) {
          console.log(item);
          // console.log("this is the function", item.name);
          this.title = item.title;
          this.description = item.description;
        }
      });
    },
  },
  computed: {
    itemsVal() {
      return [
        this.$t("travel.buttons.plane"),
        this.$t("travel.buttons.bus"),
        this.$t("travel.buttons.train"),
        this.$t("travel.buttons.car"),
      ];
    },
    travel() {
      return [
        {
          id: this.$t("travel.plane.id"),
          icon: "airplane",
          color: "blue--text",
          title: this.$t("travel.plane.title"),
          description: this.$t("travel.plane.description"),
        },
        {
          id: this.$t("travel.bus.id"),
          icon: "bus",
          color: "purple--text",
          title: this.$t("travel.bus.title"),
          description: this.$t("travel.bus.description"),
        },
        {
          id: this.$t("travel.train.id"),
          icon: "train",
          color: "blue--text",
          title: this.$t("travel.train.title"),
          description: this.$t("travel.train.description"),
        },
        {
          id: this.$t("travel.car.id"),
          icon: "car",
          color: "purple--text",
          title: this.$t("travel.car.title"),
          description: this.$t("travel.car.description"),
        },
      ];
    },
  },
  mounted() {
    this.title = this.$t("travel.plane.title");
    this.description = this.$t("travel.plane.description");
  },
};
</script>

<style lang="scss">
@import "../../assets/css/responsive/background.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/shadow.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";
</style>