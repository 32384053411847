<template>
  <div id="detail-container">
    <!-- need to have the actual pictures -->
    <h1 class="pb-5 al-heading5">{{title}}</h1>
    <v-avatar :size="eventSize" class="mb-5">
      <v-img :src="image" />
    </v-avatar>
    <div id="detail-text-container">
      <p class="al-normal-text">{{date}}</p>
      <p class="al-normal-text">{{time}}</p>
      <p class="al-normal-text pre-formatted">{{address}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Details",
  props: {
    // getting props from parent component
    title: String,
    image: String,
    date: String,
    time: String,
    address: String
  },
  computed: {
    eventSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 150;
        case "sm":
          // return 400; --
          return 200;
        case "md":
          return 200;
        case "lg":
          return 250;
        case "xl":
          return 250;
        default:
          return 250;
      }
    }
  }
};
</script>

<style>
.pre-formatted {
  white-space: break-spaces;
}
.i {
  background: blue;
  border-radius: 20px;
  font-size: 25px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
  padding: 10px;
}
</style>