<template>
  <div id="espicha" class="main">
    <v-app>
      <Header />
      <div id="espicha_sc1">
        <Section1 />
      </div>
      <div id="great_day_sc2" style="padding-bottom: 25rem">
        <Section2 />
      </div>
      <div id="bottom">
        <Footer />
      </div>
    </v-app>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Section1 from "@/components/travel/Section1.vue";
import Section2 from "@/components/travel/Section2.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "Travel",
  components: {
    Header,
    Section1,
    Section2,
    Footer,
  },
};
</script>

<style lang="scss">
@import "../assets/css/responsive/background.scss";
@import "../assets/css/backgrounds/gallery.css";

.main {
  position: relative;
}

#bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
</style>