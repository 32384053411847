<template>
  <div>
    <!-- Render1: RSVP -->
    <p class="cool-font al-heading1" style="font-weight: 500">
      {{ $t("home.section_form.rsvp.title") }}
    </p>
    <p class="al-normal-text">{{ $t("home.section_form.rsvp.subtitle") }}</p>
    <!-- field: will you attend -->
    <!-- <p class="al-normal-text text-left">{{ $t("home.section_form.rsvp.attend") }}</p> -->
    <v-radio-group :rules="rules.selection" v-model="event.selection" column>
      <v-radio
        :label="$t('home.section_form.rsvp.yes')"
        value="Sí, cuenta conmigo!"
        color="primary"
      ></v-radio>
      <v-radio
        :label="$t('home.section_form.rsvp.no')"
        value="Lo siento, no podré asistir."
        color="primary"
      ></v-radio>
    </v-radio-group>
    <!-- field: name -->
    <v-text-field
      :label="$t('home.section_form.rsvp.name')"
      v-model="event.name"
      :rules="rules.name"
      filled
      rounded
      dense
    ></v-text-field>
    <!-- field: number of people -->
    <v-text-field
      :label="$t('home.section_form.rsvp.npeople')"
      v-model="event.npeople"
      type="number"
      filled
      rounded
      dense
    ></v-text-field>
    <!-- field: contact -->
    <v-text-field
      :label="$t('home.section_form.rsvp.email')"
      v-model="event.email"
      :rules="rules.email"
      filled
      rounded
      dense
    ></v-text-field>
    <!-- field: diet -->
    <v-select
      :items="itemsVal"
      v-model="event.diet"
      :label="$t('home.section_form.rsvp.preference')"
      filled
      rounded
      dense
    ></v-select>
    <v-text-field
      :label="$t('home.section_form.rsvp.dietary.allergytypes')"
      v-if="event.diet === 'Allergies'"
      v-model="event.allergyType"
      filled
      rounded
      dense
    ></v-text-field>
    <!-- field: additional info -->
    <v-textarea
      v-model="event.notes"
      :label="$t('home.section_form.rsvp.notes')"
      filled
      rounded
      dense
    ></v-textarea>
    <v-btn @click="next(event)">{{
      $t("home.section_form.buttons.next")
    }}</v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RSVPForm",
  data: () => ({
    // snack bar
    alert: false,
    color: "",
    text: "",
    snacktimeout: 8000,

    // name, contact, selection, diet
    event: {
      selection: "",
      name: "",
      npeople: "",
      email: "",
      allergyType: "",
      diet: "",
      notes: "",
    },
  }),
  methods: {
    ...mapActions(["updateRSVP"]),
    next(event) {
      if (this.parentRefs.form.validate()) this.updateRSVP(event);
    },
  },
  computed: {
    itemsVal() {
      return [
        this.$t("home.section_form.rsvp.dietary.vegetarian"),
        this.$t("home.section_form.rsvp.dietary.allergies"),
        this.$t("home.section_form.rsvp.dietary.everything"),
      ];
    },
    // rules
    rules() {
      let selection = [
        this.event.selection.length > 0 ||
          this.$t("home.section_form.rules.selection"),
      ];
      let name = [(v) => !!v || this.$t("home.section_form.rules.name")];
      let email = [
        (v) => !!v || this.$t("home.section_form.rules.email1"),
        (v) => /.+@.+\..+/.test(v) || this.$t("home.section_form.rules.email2"),
      ];
      return { selection, name, email };
    },
  },
  props: {
    parentRefs: Object,
  },
};
</script>

<style>
</style>