<template>
  <v-container class="fill-height d-flex justify-center">
    <v-select
      :items="itemsVal"
      color="deep-purple lighten-2"
      filled
      :label="$t(`accommodation.places.select`)"
      dense
      solo
      v-model="type"
      @change="clickEvent(type)"
    ></v-select>
    <v-row class="">
      <v-col
        cols="12"
        sm="6"
        v-for="m in localAccommodation"
        :key="m.name"
        class="pa-7"
      >
        <div class="d-inline-block justify-center">
          <v-img
            class="rounded-lg"
            cover
            width="300"
            height="250"
            :src="m.image"
          />
          <p class="accommodation-title mb-0 text-left my-2">{{ m.name }}</p>
          <div class="d-flex align-center justify-start mb-2">
            <v-avatar class="elevation-3" size="30">
              <v-icon
                :class="m.category === 'Hotels' ? 'purple--text' : 'blue--text'"
                size="20"
                >{{ m.category === "Hotels" ? "mdi-bed" : "mdi-home" }}</v-icon
              >
            </v-avatar>
            <p class="mb-0 pl-2">
              {{ m.category === "Hotels" ? "Hotel" : "Casa Rural" }}
            </p>
          </div>
          <div class="d-flex justify-start my-2">
            <v-btn
              @click="goTo(m.website)"
              style="
                background-color: #d1cdc1 !important;
                color: #ffff !important;
              "
              color="deep-purple lighten-2"
              text
              >{{ $t("accommodation.places.buttons.reserve") }}</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import accommodation from "@/store/static/accommodations.js";
export default {
  name: "Section2Mobile",
  data: () => ({
    benched: 0,
    type: "",
    localEvent: "",
    localAccommodation: [],
  }),
  methods: {
    clickEvent(event) {
      this.type = event;
      if (event === "Hoteles") event = "Hotels";
      else if (event === "Casas Rurales") event = "Rural Houses";
      this.localAccommodation = [];
      if (event != "All" && event != "Todos") {
        accommodation.map((item) => {
          if (item.category === event) {
            this.localAccommodation.push(item);
          }
        });
      } else this.localAccommodation = accommodation;
    },
    goTo(link) {
      window.open(link);
    },
  },
  computed: {
    itemsVal() {
      return [
        this.$t("accommodation.places.buttons.all"),
        this.$t("accommodation.places.buttons.hotels"),
        this.$t("accommodation.places.buttons.houses"),
      ];
    },
  },
  mounted() {
    this.localAccommodation = accommodation;
  },
};
</script>

<style scope lang="scss">
@import "../../assets/css/responsive/space.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";

.accommodation-title {
  font-size: 23px;
}
</style>