export const accommodation = [
    {
        "name": "Camin de Pelayo",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/camin_de_pelayo.jpg",
        "coordinates": {
            lat: 43.37461398036463,
            lng: -5.147186750258496
        },
        "website": "https://www.camindepelayo.es/",
        "category": "Hotels"
    },
    {
        "name": "Casa la Prida",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/casa_la_prida.jpg",
        "coordinates": {
            lat: 43.37150847747536,
            lng: -5.150327549741749
        },
        "website": "http://casalaprida.com/",
        "category": "Rural Houses"
    },
    {
        "name": "Casa Marian",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/casa_marian.jpg",
        "coordinates": {
            lat: 43.33618803355116,
            lng: -5.137364711637789
        },
        "website": "https://www.casamarian.es/",
        "category": "Rural Houses"
    },
    {
        "name": "Casa Rural la Faya",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/casa_rural_la_faya.jpg",
        "coordinates": {
            lat: 43.35748569066779,
            lng: -5.146837799984498
        },
        "website": "https://casa-rural-la-faya.business.site/",
        "category": "Rural Houses"
    },
    {
        "name": "Casa el Quintanal",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/casa_rural_el_quintanal.jpg",
        "coordinates": {
            lat: 43.33056050208867,
            lng: -5.137527586493858
        },
        "website": "https://casaelquintanal.com/",
        "category": "Rural Houses"
    },
    {
        "name": "Parador Cangas de Onis",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/hotel_parador_de_cangas_de_onis.jpg",
        "coordinates": {
            lat: 43.367103669863944,
            lng: -5.150861684639375
        },
        "website": "https://www.parador.es/",
        "category": "Hotels"
    },
    {
        "name": "Cenador Canonigos",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/hotel_cenador_canonigos.jpg",
        "coordinates": {
            lat: 43.3585929962941,
            lng: -5.129994847872654
        },
        "website": "https://www.tripadvisor.com/Restaurant_Review-g662890-d4059151-Reviews-El_Cenador_de_los_Canonigos-Cangas_de_Onis_Asturias.html",
        "category": "Hotels"
    },
    {
        "name": "Hotel Gran Pelayo",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/hotel_gran_pelayo_fachada.jpg",
        "coordinates": {
            lat: 43.31602541085539,
            lng: -5.053108038733686
        },
        "website": "https://www.granhotelpelayo.com/",
        "category": "Hotels"
    },
    {
        "name": "Casona de Sobrepiedra",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/la_casona_sobre_piedra.jpg",
        "coordinates": {
            lat: 43.372299759855004,
            lng: -5.150078573001438
        },
        "website": "https://www.escapadarural.com/casa-rural/asturias/la-casona-de-sobrepiedra",
        "category": "Rural Houses"
    },
    {
        "name": "Casa de Aldea Belarmino",
        "image": "https://d2vccncxnbf4da.cloudfront.net/accommodations/casa_de_aldea_belarmino.jpg",
        "coordinates": {
            lat: 43.37535001890227,
            lng: -5.150821342327558
        },
        "website": "http://casadealdeabelarmino.com/",
        "category": "Rural Houses"
    },
]

export default accommodation