<template>
  <div
    class="pa-sec2a-brownbackground ma-sec2a-brownbackground"
    style="background-color: #d1cdc1"
  >
    <div class>
      <p class="mb-0 cool-font al-subtitle sm-al-subtitle2">
        {{ $t("home.section_quote.quote") }}
      </p>
      <p class="mb-0 al-heading4 text-right">Tío Simón</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Section2a",
};
</script>

<style scoped lang="scss">
@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";
</style>