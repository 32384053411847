<template>
  <v-container>
    <div class="px-general">
      <div class="d-flex align-end justify-center" style="margin-top: 4rem">
        <p class="cool-font al-subtitle">
          {{ $t("travel.title") }}
        </p>
      </div>
      <div class="greatday-pt-text">
        <p class="al-heading5 text-center">
          {{ $t("travel.description1") }}
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section1",
};
</script>

<style lang="scss">
@import "../../assets/css/responsive/background.scss";
@import "../../assets/css/common/font.css";
@import "../../assets/css/shadow.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";
</style>