<template>
  <v-container>
    <div
      class="px-general background-accommodation position-accommodation-flowers"
    >
      <!-- Introductory -->
      <div class="d-flex align-end justify-center" style="margin-top: 4rem">
        <div class>
          <p class="cool-font al-subtitle">
            {{ $t("accommodation.main.title") }}
          </p>
          <p
            class="al-normal-text text-left pt-15"
            style="white-space: pre-line"
          >
            {{ $t("accommodation.main.text") }}
          </p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section1",
};
</script>

<style scope lang="scss">
@import "../../assets/css/backgrounds/accommodation.css";
@import "../../assets/css/responsive/background.scss";

@import "../../assets/css/common/font.css";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";
</style>