<template>
  <!-- this would have to be dynamic height -->
  <!-- <v-parallax
    height="1000"
    src="https://d2vccncxnbf4da.cloudfront.net/asturias/Oviñana-Banco-Mirador.jpg"
  >-->
  <div
    class="
      background-positioning
      asturias-section1-background-image
      section1-image-height
    "
  >
    <v-container class="fill-height">
      <v-row>
        <v-col class="d-inline-block align-center">
          <p class="al-title cool-font">Asturias</p>
          <p class="al-heading1">{{ $t("asturias.section_title.paradise") }}</p>
        </v-col>
      </v-row>
    </v-container>
    <!-- </v-parallax> -->
  </div>
</template>

<script>
export default {
  name: "Section1",
};
</script>

<style scoped lang="scss">
@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";
</style>
