import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./actions/index"
import mutations from "./mutations/index"
Vue.use(Vuex)

/*
    TYPE
    rsvpEvent: {
        name: "",
        npeople: "",
        email: "",
        allergyType: "",
        selection: "",
        diet: "",
        notes: "",
        cotrans: "",
        estrans: ""
    }
*/


export const defaultState = () => {
    return {
        locales: [
            {
                text: 'EN',
                flag: 'https://d2vccncxnbf4da.cloudfront.net/languages/english.png',
                value: 'en'
            },
            {
                text: 'ES',
                flag: 'https://d2vccncxnbf4da.cloudfront.net/languages/spanish.png',
                value: 'es'
            }
        ],
        // Covadonga, default coordinates
        coordinates: [
            {
                lat: 43.30875286678579,
                lng: -5.055433929195592
            }
        ],
        rsvpEvent: {},
        formState: "rsvp_details",
        formName: "form",
        valid: true
    }
}

export default new Vuex.Store({
    state: defaultState,
    mutations: mutations,
    actions: actions
})
