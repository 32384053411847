<template>
  <v-container>
    <div class="px-general ma-general-space">
      <div class="greatday-mt">
        <p class="cool-font al-heading1">
          {{
            $t("great_day.espicha_section.subsection_2_where.where_when_title")
          }}
        </p>
        <p class="mb-2 al-heading5 espicha-px">
          {{ $t("great_day.espicha_section.subsection_2_where.where_when") }}
        </p>
        <p class="mb-0 al-heading5 espicha-px">
          {{ $t("great_day.espicha_section.subsection_2_where.address") }}
        </p>
        <p class="espicha-sub-mt cool-font al-heading3">
          {{ $t("great_day.how_to_get_title") }}
        </p>
        <p class="mb-0 al-heading5 espicha-px">
          {{ $t("great_day.espicha_section.subsection_2_where.how_to_get") }}
        </p>
        <p class="espicha-sub-mt cool-font al-heading3">
          {{ $t("great_day.dress_code_title") }}
        </p>
        <p class="mb-0 al-heading5 espicha-px">
          {{ $t("great_day.espicha_section.subsection_2_where.dress_code") }}
          <a
            href="https://pin.it/7hEtBhN"
            target="_blank"
            style="outline: none; text-decoration: none"
            >https://pin.it/7hEtBhN</a
          >.
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section3",
};
</script>

<style lang="scss">
@import "../../../assets/css/responsive/background.scss";
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/shadow.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>