<template>
  <v-container>
    <div class="px-general ma-general-space">
      <!-- Introductory -->
      <div class="d-flex align-end justify-center" style="margin-top: 4rem">
        <p class="cool-font al-subtitle">
          {{ $t("great_day.espicha_section.title") }}
        </p>
      </div>
      <v-row class="mt-15" no-gutters>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <v-card class="pa-8" :width="width" :elevation="elevation">
            <v-carousel
              cycle
              interval="4500"
              :height="height"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="(item, i) in items"
                :key="i"
                :src="item.src"
              >
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
        <v-col cols="12" md="5" class="d-flex align-center justify-center">
          <div class="greatday-pt-text">
            <p class="al-heading5 text-center text-md-left">
              {{ $t("great_day.espicha_section.subsection_1_what.pre_text") }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section1",
  data: () => ({
    items: [
      {
        src: "https://d2vccncxnbf4da.cloudfront.net/details/espicha.jpeg",
      },
      {
        src: "https://d2vccncxnbf4da.cloudfront.net/details/espicha_asturiana.jpeg",
      },
    ],
  }),
  computed: {
    elevation() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 5;
        case "sm":
          return 8;
        case "md":
          return 10;
        case "lg":
          return 12;
        case "xl":
          return 12;
        default:
          return 8;
      }
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 350;
        case "sm":
          return 350;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 600;
        default:
          return 300;
      }
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 180;
        case "sm":
          return 180;
        case "md":
          return 300;
        case "lg":
          return 350;
        case "xl":
          return 350;
        default:
          return 150;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/responsive/background.scss";
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/shadow.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>