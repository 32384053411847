<template>
  <v-app-bar
    :style="{ backgroundColor: dynamic }"
    app
    elevate-on-scroll
    class="header"
  >
    <v-toolbar-title
      @click="navigateTo({ name: 'home' })"
      class="header-title cursor-pointer"
      style="cursor: pointer"
    >
      <!-- bigger width -->
      <v-img
        alt="AdrianYLuisanaLogo"
        class="shrink mr-2"
        contain
        src="https://d2vccncxnbf4da.cloudfront.net/logo/logo_03_ai_logo encabezado.png"
        transition="scale-transition"
        width="40"
      />
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <v-btn
        v-for="route in routes"
        :key="route.id"
        :ripple="false"
        class="hidden-sm-and-down text-capitalize"
        text
        :to="route"
        >{{ $t(`routes.${route}`) }}</v-btn
      >

      <!-- Great Day menu -->
      <v-menu :offset-y="offset" open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="hidden-sm-and-down text-capitalize black--text"
            text
            :ripple="false"
            dark
            v-bind="attrs"
            v-on="on"
          >
            {{ $t("routes.greatday") }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            class="cursor-pointer v-items"
            v-for="(item, index) in items"
            :key="index"
          >
            <v-list-item-title>
              <v-btn class="text-capitalize" :ripple="false" text :to="item">{{
                $t(`routes.${item}`)
              }}</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- 
          Language menu 
          this thing needs to work in smaller menus
      -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            text
            class="text-capitalize"
            v-bind="attrs"
            v-on="on"
          >
            {{ locale }}
            <v-img class="ml-1" :src="locale_flag" contain width="20" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in locales"
            :key="item.id"
            @click="changeLocale(item.value)"
          >
            <!-- <v-list-item> -->
            <v-list-item-title>
              <!-- <v-list-item-title class="d-flex align-center"> -->
              <v-btn :ripple="false" text class="text-capitalize">
                {{ item.text }}
                <v-img class="ml-1" :src="item.flag" contain width="20" />
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Small screens -->
      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="hidden-md-and-up" icon v-on="on">
            <v-icon class>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="route in routesMobile"
            :key="route.id"
            class="cursor-pointer v-items"
          >
            <v-list-item-title>
              <v-btn :ripple="false" text :to="route">{{
                $t(`routes.${route}`)
              }}</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    limitPosition: 1,
    lastPosition: 0,
    dynamic: "transparent",
    scrolled: false,
    offset: true,
  }),
  computed: {
    locales() {
      return this.$store.state.locales;
    },
    locale() {
      const idx = this.locales.findIndex((e) => e.value === this.$i18n.locale);
      return this.locales[idx].text;
    },
    locale_flag() {
      const idx = this.locales.findIndex((e) => e.value === this.$i18n.locale);
      return this.locales[idx].flag;
    },
    routes() {
      // Show/Sort Routes by Route Name
      return ["asturias", "gallery", "accommodation", "travel"];
    },
    routesMobile() {
      return [
        "asturias",
        "gallery",
        "accommodation",
        "espicha",
        "ceremony",
        "celebration",
        "travel",
      ];
    },
    items() {
      return ["espicha", "ceremony", "celebration"];
    },
  },
  methods: {
    changeLocale(locale) {
      this.$store.dispatch("changeLocale", locale);
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    navItemsColor() {
      this.dynamic = "transparent";
      this.showItems = true;
    },
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        // this.dynamic = "rgba(38, 38, 38, 0.77)";
        // this.dynamic = "rgba(200, 218, 233, 0.77)"; // blue
        // this.dynamic = "rgba(204, 191, 219, 0.77)"; // pink
        this.dynamic = "rgba(209, 205, 193, 1)"; // creme
        this.dynamic = "#f5f5f5"; // white
      }

      if (this.lastPosition == window.scrollY) {
        this.scrolled = false;
        this.dynamic = "transparent";
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.navItemsColor();
  },
};
</script>

<style>
.header {
  /* height: 94px; */
  position: fixed !important;
  z-index: 1;
}
.headroom {
  transition: all 0.3s ease-in-out;
}

#nav-btn {
  color: white;
  text-decoration: none;
}
#nav-btn:hover {
  color: #f5f6f4;
  /* color: green; */
}
#nav-btn:active {
  color: #f5f6f4;
}
</style>