<template>
  <div class="d-flex justify-center align-center">
    <!-- <div class=""> -->
    <!-- height would change on screensize -->
    <GmapMap
      :center="center"
      :zoom="12"
      style="height: 600px"
      class="map-width"
    >
      <!-- <GmapMap :center="center" :zoom="12" style="height: 600px"> -->
      <GmapMarker
        v-for="(m, index) in coordinates"
        :key="index"
        :position="m"
        @click="center = m[0]"
      >
      </GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GoogleMap",
  data() {
    return {
      center: {
        lat: 43.34174939924332,
        lng: -5.101782474002718,
      },
    };
  },
  computed: {
    ...mapState(["coordinates"]),
  },
};
</script>

<style scope lang="scss">
@import "../../assets/css/responsive/space.scss";
</style>