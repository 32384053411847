<template>
  <v-carousel
    cycle
    :interval="interval"
    :height="height"
    hide-delimiter-background
    :show-arrows="false"
  >
    <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
      <div class="fill-height d-flex justify-center align-center">
        <p class="mt-title al-title white--text cool-font">
          {{ $t("home.section_title.subtitle") }}
        </p>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Section1",
  data: () => ({
    interval: 2500,
    items: [
      {
        src: "https://d2vccncxnbf4da.cloudfront.net/backgrounds/sections/sct1v_a.jpg",
      },
      {
        src: "https://d2vccncxnbf4da.cloudfront.net/backgrounds/sections/sct1_v3_a.jpg",
      },
      {
        src: "https://d2vccncxnbf4da.cloudfront.net/backgrounds/sections/sct1_v2_a.jpg",
      },
      {
        src: "https://d2vccncxnbf4da.cloudfront.net/backgrounds/sections/sct1_v4_a.jpg",
      },
    ],
  }),
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 350;
        case "sm":
          return 500;
        case "md":
          return 750;
        case "lg":
          return 1000;
        case "xl":
          return 1000;
        default:
          return 1000;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/backgrounds/home.css";

@import "../../assets/css/responsive/font.scss";
@import "../../assets/css/responsive/space.scss";
@import "../../assets/css/colors.css";
@import "../../assets/css/boxes.css";

.mt-title {
  margin-top: 10rem;
}
</style>
