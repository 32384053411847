<template>
  <v-container>
    <div class="px-general" style="">
      <div
        class="d-flex align-end justify-center"
        style="white-space: pre-line; line-height: 0.8; margin-top: 4rem"
      >
        <p class="cool-font al-subtitle">
          {{ $t("great_day.ceremony_section.title") }}
        </p>
      </div>
      <div class="greatday-pt-text greatday-mt-sec1">
        <p class="al-heading5 text-center">
          {{ $t("great_day.ceremony_section.subsection1") }}
        </p>
      </div>
      <section class="mt-15">
        <iframe
          :width="videoWidth"
          :height="videoHeight"
          src="https://www.youtube.com/embed/dI7e2ICwCTQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </section>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Section1",
  computed: {
    videoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 320;
        case "sm":
          return 450;
        case "md":
          return 600;
        case "lg":
          return 700;
        case "xl":
          return 700;
        default:
          return 700;
      }
    },
    videoHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 200;
        case "sm":
          return 250;
        case "md":
          return 350;
        case "lg":
          return 400;
        case "xl":
          return 400;
        default:
          return 400;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/responsive/background.scss";
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/shadow.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>