<template>
  <v-container style="height: 100%">
    <div class="ma-general-space" style="height: 100%">
      <!-- outer container: picture shown in bigger screens -->
      <div class="outer-container-sec5 sp-oc">
        <div class="container-sec5 primary-shadow space-row-xl-only">
          <v-row no-gutters class="sp-sec5pa">
            <v-col
              cols="12"
              lg="6"
              class="hidden-sm-down d-flex justify-center align-center"
            >
              <div class="hidden-md-and-down">
                <v-img
                  width="490"
                  contain
                  style="border-radius: 3% !important"
                  src="https://d2vccncxnbf4da.cloudfront.net/forms/sc5.jpeg"
                />
              </div>
            </v-col>
            <v-col cols="12" lg="6" class="d-flex justify-center align-center">
              <Forms />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Forms from "@/components/common/Forms.vue";
export default {
  name: "Section5",
  components: {
    Forms,
  },
};
</script>

<style scope lang="scss">
@import "../../../assets/css/common/font.css";
@import "../../../assets/css/responsive/space.scss";
@import "../../../assets/css/backgrounds/home.css";
@import "../../../assets/css/colors.css";
@import "../../../assets/css/boxes.css";
</style>