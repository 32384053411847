export const photos = [
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_1.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_2.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_3.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_10.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_4.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_5.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_6.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_7.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_8.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_12.jpg",
        "category": "preboda",
    },
    {
        "url": "https://d2vccncxnbf4da.cloudfront.net/gallery/al_pre_photos_9.jpg",
        "category": "preboda",
    },
]

export default photos